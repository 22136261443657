import React from 'react';
import { OurApproachViewContext, TemplateProps } from '../parser';
import { PathService } from '../services';
import { OurApproachView } from '../views';

const OurApproach: React.FunctionComponent<TemplateProps<
  OurApproachViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <OurApproachView
      page={JSON.parse(pageContext.page)}
      content={JSON.parse(pageContext.content)}
    />
  );
};

export default OurApproach;
